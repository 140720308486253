<template>
  <div
    id="cancel-ticket"
    class="page"
    :class="ticket && ticket.status ? ticket.status : null"
  >
    <div class="container" ref="container">
      <div class="page-header">
        <h1 class="title">
          {{ $__t("cancel reservation") | capitalizeFirstLetter }}
        </h1>
      </div>

      <div class="page-body">
        <div class="section">
          <div class="label">
            <span>{{ label }}</span>
          </div>
          <div class="primary">
            {{ primary }}
          </div>
          <div class="secondary">
            <p>{{ ticket?.product?.product_name }}</p>
            <p>{{ ticketTime }}</p>
          </div>
        </div>
        <div class="section">
          <h2>
            {{
              $__t(
                "Please select one of the reasons for cancellation of booking"
              )
            }}
          </h2>

          <ul class="options">
            <li
              class="option"
              v-for="(reason, i) in formData.cancel_reason"
              :key="i"
            >
              <input v-model="selectedReason" :id="i" :value="i" type="radio" />
              <i @click="selectedReason = i" class="fas fa-check"></i>
              <label class="label" :for="i">{{ reason.label }}</label>
              <input
                v-if="reason.detail !== undefined"
                v-model.trim="reason.detail"
                :placeholder="reason.placeholder"
                type="text"
                @focus="
                  selectedReason = i;
                  reason.error = '';
                "
              />
              <div v-if="reason.error" class="error">{{ reason.error }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-footer">
        <div class="button-area">
          <button type="button" v-button class="close" @click="$router.back()">
            {{ $__t("닫기") }}
          </button>
          <button
            ref="submit"
            v-button
            type="button"
            class="submit"
            @click="selectedReason ? submitForm() : ''"
          >
            {{ $__t("Cancel Booking") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    let formData = {
      cancel_reason: {
        plan_change: {
          value: "cancel_reason.plan-change", // 일정 변경 ㅇ
          label: this.$__t("cancel_reason.plan-change"),
        },
        store_change: {
          value: "cancel_reason.store-change", // 보관 상점 변경 ㅇ
          label: this.$__t("cancel_reason.wrong-store"),
        },
        host_cancel: {
          value: "cancel_reason.host-cancel", // 호스트 예약 취소 ㅇ
          label: this.$__t("cancel_reason.host-cancel"),
        },
        not_public: {
          value: "cancel_reason.not-public", // 운영중인 상점
          label: this.$__t("cancel_reason.not-public"),
        },
        shut_down: {
          value: "cancel_reason.shut-down", // 폐업
          label: this.$__t("cancel_reason.shut-down"),
        },
        etc: {
          value: "cancel_reason.etc",
          label: this.$__t("cancel_reason.etc"),
          detail: "",
          error: "",
          placeholder: this.$__t("Please type a reason for cancellation."),
        },
      },
    };

    return {
      formData,
      selectedReason: "",
    };
  },

  mounted() {
    setTimeout(async () => {
      await this.fetchTicket();

      this.toggleHeaderTitleVisibility();
      this.$refs.container.addEventListener(
        "scroll",
        this.toggleHeaderTitleVisibility
      );
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    this.$refs.container.removeEventListener(
      "scroll",
      this.toggleHeaderTitleVisibility
    );
  },

  watch: {
    selectedReason(value) {
      this.formData.cancel_reason.etc.error = "";

      if (value) {
        this.$refs.submit.classList.add("activated");
      } else {
        this.$refs.submit.classList.remove("activated");
      }
    },
  },

  computed: {
    ticket() {
      return this.$store.getters["tickets/GET_ITEM"]({
        key: "ticket_code",
        value: this.id,
      });
    },

    label() {
      let label = "";

      switch (this.ticket?.status) {
        case "ISSUED":
          label = this.$__t("예약완료");
          break;
        case "CHECK_IN":
          label = this.$__t("보관완료");
          break;
        case "CHECK_OUT":
          label = this.$__t("찾기완료");
          break;
        case "CANCELLED":
          label = this.$__t("취소됨");
          break;
        default:
          label = this.ticket?.status;
          break;
      }

      return label;
    },

    primary() {
      return this.ticket?.store_name ?? "";
    },

    ticketTime() {
      let value = "";

      const dateStartString = this.ticket?.ticket_date_start
        ? this.$moment(this.ticket.ticket_date_start).format(
            this.$__t("MM월 DD일 HH:mm")
          )
        : null;
      const dateEndString = this.ticket?.ticket_date_end
        ? this.$moment(this.ticket.ticket_date_end).format(
            this.$__t("MM월 DD일 HH:mm")
          )
        : null;

      value = `${dateStartString} ~ ${dateEndString}`;

      return value;
    },

    ticketQuantity() {
      let value = "";

      if (this.ticket?.ticket_quantity > 0) {
        let quantityString = `${this.$__t("{luggage-selector}.small")} ${
          this.ticket.ticket_quantity
        }`;

        if (this.ticket.ticket_quantity > 1) {
          value += quantityString + this.$__t("item");
        } else {
          value += quantityString + this.$__t("items");
        }
      }

      if (this.ticket?.ticket_over_quantity > 0) {
        let quantityString = `${this.$__t("{luggage-selector}.big")} ${
          this.ticket.ticket_over_quantity
        }`;

        if (this.ticket.ticket_quantity > 0)
          quantityString = " " + quantityString;

        if (this.ticket.ticket_over_quantity > 1) {
          value += quantityString + this.$__t("item");
        } else {
          value += quantityString + this.$__t("items");
        }
      }

      return value;
    },
  },

  methods: {
    fetchTicket() {
      return this.$store.dispatch("tickets/get", { id: this.$props.id });
    },

    async submitForm() {
      if (
        this.selectedReason === "etc" &&
        !this.formData.cancel_reason.etc.detail
      ) {
        this.formData.cancel_reason.etc.error = this.$__t(
          "__VALIDATION_ERROR_REQUIRED"
        );
        return;
      } else {
        this.formData.cancel_reason.etc.error = "";
      }

      this.$store.commit("loading/SET_TRUE");

      try {
        let res,
          { detail, value } = this.formData.cancel_reason[this.selectedReason];

        const params = {
          receipt_id: this.ticket.receipt_id,
          cancel_reason_code: value,
          cancel_reason_detail: value === "cancel_reason.etc" ? detail : "",
        };

        res = await this.$store.dispatch("serviceProduct/cancel", params);

        this.$analytics.logEvent("cancel reservation");

        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("예약이 취소되었습니다."),
          status: "success",
        });
      } catch (e) {
        console.error(e);

        this.$store.commit("alert/ADD_ITEM", {
          message: "취소가 불가한 티켓입니다. 고객센터로 문의 부탁드립니다.",
          status: "error",
        });
      } finally {
        this.fetchTickets().then(() => {
          setTimeout(() => {
            this.$store.commit("loading/SET_FALSE");
            this.$router.back();
          }, 50);
        });
      }
    },

    async fetchTickets(options = {}) {
      try {
        this.$store.commit("tickets/CLEAR_ITEMS");
        this.$store.commit("tickets/CLEAR_ALL_LIST");
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_all",
          filter: "all",
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_available",
          filter: "available",
          query: { page: 1, size: 10 },
        });

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      }
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $(".page-header > .title", this.$el);
      const $siteHeaderTitle = $(
        "#site-header > .container > .header > .title"
      );
      const $siteHeader = $("#site-header");

      if (clear === true) {
        $siteHeaderTitle.removeClass("off");
        return;
      }

      const isHeaderCovered =
        $pageHeaderTitle.offset().top - $pageHeaderTitle.outerHeight() <
        window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass("off");
        $siteHeader.removeClass("shadow");
      } else {
        $siteHeaderTitle.removeClass("off");
        $siteHeader.addClass("shadow");
      }
    },
  },
};
</script>
